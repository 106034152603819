.btn-gradient {
	background: linear-gradient(to top, black , #6c6d6f); /* Standard syntax */
	padding 10px 30px
	font-family openSans
	color white
	font-weight 600
	font-size 14px
	text-transform uppercase
	text-align center
	text-decoration none
	margin-top 20px
	transition 0.5s ease

	&:hover {
		transition 0.5s ease
		color #545454
		background: linear-gradient(to top, black , #1f1f1f); /* Standard syntax */
	}
}

.btn--copper {
	background-color mbaCopper
	padding 10px 30px!important
	font-family openSans
	color white!important
	font-weight 500
	font-size 14px
	text-transform uppercase
	text-align center
	text-decoration none
	margin-top 20px
	transition 0.5s ease
	text-decoration: none
	text-transform: capitalize!important;

	padding: 10px 30px!important;

	&:hover {
		color white!important
		transition 0.5s ease
		background-color darken(mbaCopper,20%)
	}
} 

.btn--blue {
	background-color mbaBlue
	padding 10px 30px
	font-family openSans
	color white
	font-weight 600
	font-size 14px
	text-transform uppercase
	text-align center
	text-decoration none
	margin-top 20px
	transition 0.5s ease

	&:hover {
		color: white!important;
		transition 0.5s ease
		background-color darken(mbaBlue,20%)
	}
} 


.disabled {
	// opacity 0.6
	// pointer-events: none
}

.btn, .wc-backward {

	font-family openSans
	font-size 16px
	color white
	font-weight 500
	position relative
	text-decoration none
	padding 10px 20px

	&.shop-red {
		background-color mbaCopper
		// border 2px solid mbaBtnBlack
		transition 0.4s ease
		float: right;

		&:hover {
			background-color darken(mbaCopper,20%)
			transition 0.4s ease
		}

		// @media(max-width mobile) {
		// 	border 2px solid mbaRed
		// }
	}

	&.full-filter, &.reduced-filter {
		background-color mbaBlue
		// border 2px solid mbaBtnBlack
		transition 0.4s ease

		&:hover {
			background-color darken(mbaBlue,20%)
			transition 0.4s ease
		}

		// @media(max-width mobile) {
		// 	border 2px solid mbaRed
		// }
	}

	&.shop-blue {
		background-color mbaBlue
		// border 2px solid mbaGrey
		color white
		text-transform uppercase
		padding 5px 10px
		transition 0.4s ease
		margin-bottom 20px
		margin-top 20px

		&:hover {
			background-color darken(mbaBlue,20%)
			transition 0.4s ease
		}
	}

	&.checkout {
		padding-left 50px

		&:after {
			content ''
			position absolute
			left 14px
			top 9px
			display block
			width 20px
			height 20px
			background-image url('../images/icons/basket.svg')
			background-size contain
			background-repeat no-repeat
			background-position center center
		}
	}
}

.wc-backward, .wc-forward {
	background-color mbaBlue!important
	color white!important
	font-weight 500!important
	padding 15px 30px!important
	border-radius 0px!important
	transition 0.4s ease

	&:hover {
		background-color darken(mbaBlue, 20%)!important
		transition 0.4s ease
	}
}

.cart-empty {
	margin-bottom 40px
}

.continue-shopping {
	background-color mbaBlue
	padding: 14px 40px
	margin-right 20px
	font-size 14px
	transition 0.4s ease
	text-align center

	&:hover {
		background-color darken(mbaBlue, 20%)
		transition 0.4s ease
	}

	@media(max-width 600px) {
	    width 100%
	    display block
	}
}

.filter-btn, .filter-btn-top-level {
	@media(min-width 680px) {
		display none
	}

	display block
	background mbaBlue
	font-size 14px
	float right
	width 85px!important
}

.filter-btn-top-level {
	float left
	cursor pointer
	margin-bottom 10px

	@media(max-width 600px) {
		margin-bottom 25px
		margin-top 0px
		margin-left 5px
	}
}
