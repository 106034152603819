.news {
    margin-bottom 60px

    img {
        margin-bottom 30px
    }

    h2 {
        margin-bottom 25px
    }

    h2:first-of-type{
        position relative
        
        &:after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: #323e6a;
            position: absolute;
            bottom: -15px;
            left: 0;
        }
    }

    a {
        color: mbaCopper
        transition: 0.4s ease
        font-weight: 500

        &:hover {
           color: mbaBlue
        }
    }
}

.more-information-box {
    margin-bottom 0px!important

    h4 {
        color: mbaBlack
    }

h4:last-of-type {
    margin-top: 30px
}
}

.product__container {
    display block
    float left
    padding 0px 0px 60px 0px
    position relative

    &:nth-last-of-type(1){
        margin-bottom 60px
    }

    .span6 {
        a {
            position relative
        }
    }

    a {
        position absolute
        bottom 20px
        right 15px
        font-family openSans
        font-size 17px
        font-weight 700
        color mbaBlue
        text-transform uppercase
        text-decoration none
        transition 0.4s ease-in-out
        padding: 10px 30px!important

        &.view-more {
            right 130px
            cursor pointer
        }

        &:hover {
            color darken(mbaBlue, 20%)
            transition 0.4s ease-in-out
        }
    }
}

.divider {
    background-color mbaBlue
    height 2px
    width 100%

}
.blog-item {

    position relative
    padding-bottom 10px
    padding-top 40px
    border-bottom 2px solid mbaBlue

    a {
        text-decoration none
    }
}

.post-categories {

    padding-left 15px

    li {
        list-style none
        margin-left 0px
        display inline-block
        width auto
        padding-right 35px

        a {
            text-decoration none
            color mbaTextGrey
            font-size 16px
            font-weight 600
            position relative

            &:before {
                content '#'
                display block
                position absolute
                top -1px
                left -15px

            }

        }
    }
}

.date {
    text-decoration none
    color mbaTextGrey
    font-size 16px
    font-weight 600
}

.btn-read-more {
    position absolute
    bottom 7px
    right 0
    font-family openSans
    color mbaBlue
    font-size 18px
    text-transform uppercase
    font-weight 700

    &.blog-page {
        position relative
        text-decoration none
        margin-top 50px
        display inline-block
    }
}

.blog-sidebar {
    position absolute
    text-align right
    top 0
    left -150px

    @media(max-width tablet) {
        position relative
        top 0
        left 0
        text-align left
    }

    .post-categories {
        margin-bottom 0px

        li {
            display block
            padding-right 0px
        }
    }
}


.blog-content a {
    color mbaBlue
}