body {
	position: relative;
	width: 100%;
	left: 0;
	transition: left 0.3s ease;
    font-size 24px;
	background-color mbaBackground
	font-family openSans

	&.open {
		position: fixed;
		left: 240px;
	}

	&.search-active {
		position: fixed;
		width: 100%;
	}
}

html,body {
	@media(max-width mobile) {
		overflow-x hidden
	}
}

iframe {
	width 100%
}

* {
	box-sizing: border-box;
}

.inline{
	display: inline-block;
}

strong{
	font-weight: bold;
}

ul {
	padding-left: 0;
}

img {
	 max-width: 100%;
	 height: auto;
	 vertical-align: bottom;
}

#breadcrumbs{
    list-style:none;
    margin:10px 0;
    overflow:hidden;
	margin-left 9px
	position: relative;
	z-index: 9999;

	@media(max-width mobile) {
		line-height 20px
	}

	.bread-cat {
		font-family openSans
		color #6c6d6f
		font-size 16px
		line-height 0px
		font-weight 700
		text-decoration none
		transition 0.4s ease

		&:hover{
			color mbaBlue
			transition 0.4s ease
		}
	}

	li {
	    display:inline-block;
	    vertical-align:middle;
	    margin-right:7.6px;

		&:nth-of-type(1){
			display none
		}
	}

	.separator{
	    font-size:16px;
		line-height 0px
	    font-weight:700;
		font-family: openSans;
	    color: #6c6d6f;
		margin-top: 8px;
	}

	.bread-current {
		color mbaBlue
		font-size 16px
		line-height 0px
	}
}


.separator-home {
	display none!important
}

.header__contact--mobile {
	text-align center

	a {
		color white
		font-size 18px
		margin 0px 10px
		text-transform uppercase
		font-weight 700
		text-decoration none
	}
}
