// Declare Font Family, Font Sizes for Global styles here

body {
	font-family: Arial, Helvetica, sans-serif;
}

html {
	font-size: 16px;
	font-weight: 100;
	line-height: 1.5rem;

	@include max-mobile() {
		font-size: 14px;
	}
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
	margin-top: 0;
	margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 1rem;
}

h1 {
	margin-bottom: 2rem;
}

h2 {
	margin-bottom: 1.5rem;
}

/* MBA Unique Styles */

h1 {
	font-family: 'Audiowide', cursive;
	font-weight: normal;
	font-size 40px
	color mbaBlue
	font-weight 700
	position relative
	margin-bottom 20px

	&:after {
		content ''
		width 100%
		height 2px
		background-color mbaBlue
		position absolute
		bottom -15px
		left 0
	}
}

h2 {
	// font-family Roboto
	// font-size 30px
	// color mbaBlue
	// font-weight 700
	// position relative

	font-family openSans
	font-size 25px
	color mbaBlue
	font-weight 700
	margin-bottom 30px

	// &:after {
	// 	content ''
	// 	width 100%
	// 	height 2px
	// 	background-color mbaBlue
	// 	position absolute
	// 	bottom -15px
	// 	left 0
	// }
}

h3 {
	font-family openSans
	font-size 25px
	color mbaBlue
	font-weight 700
	margin-bottom 30px
}

h5 {
	line-height: 1.4;
}

p {
	font-family openSans
	font-size 17px
	line-height 29px
	font-weight 300
	color mbaTextGrey
}

.text-center {
	@media(max-width 600px) {
		padding-top 40px
		padding-bottom 20px
	}
}
