.shop-hero {
    height 500px
    background-position center center
    background-repeat no-repeat
    width 100%
    margin-top -50px
    margin-bottom 50px

    @media(max-width 1190px) {
        background-size 200%!important
    }

    @media(max-width 800px) {
        background-size 350%!important
    }

    @media(max-width 680px) {
        height auto
        margin-bottom 20px
    }

    .row {
        width 100%
    }

    .span6 {
        float right

        h1 {
            color white
         	font-family: 'Audiowide', cursive;
	        font-weight: normal;
            font-size 64px
            line-height 64px
            // font-weight 700
            padding-top 150px
            text-shadow 2px 2px 7px mbaDarkGrey

            @media(max-width 1190px) {
                font-size 48px
            }

            @media(max-width 935px) {
                font-size 38px
            }

            @media(max-width 680px) {
                padding-top 100px
            }

            @media(max-width mobile) {
                font-size 32px
                padding-top 50px
                padding-bottom 40px
                line-height 32px
            }

            &:after {
                display none
            }
        }
    }
}

.grid {
    [class^='span'] {
        height 280px
        margin-bottom: 30px
        position relative
        background-size cover
        background-position center center
        background-repeat no-repeat
        transition 0.4s ease
        background-color white
        border-bottom 4px solid mbaBlue

        p {
            position absolute
            bottom 0
            left 0
            margin-bottom 0px
            font-family openSans
            color white
            font-size 16px
            font-weight: 600
            text-transform uppercase
            padding 10px 30px 10px 15px
            width 100%
            padding-top 0px
            transition 0.4s ease
            z-index 5
        }

        .bluebar {
            width 100%
            min-height 43px
            position absolute
            bottom 0
            left 0
            background rgba(mbaBlue, 60%)
            width 100%
            z-index 4
            transition 0.4s ease

            &:before {
                content ''
                width 0%
                height 100%
                position absolute
                top 0
                left 0
                background-color mbaBlue
                transition 0.4s ease
            }
        }

        &:hover {

            transition 0.4s ease

            .bluebar {

                &:before {
                    width 100%
                    transition 0.4s ease
                }
            }
        }

        &.half {
            height 250px
        }

        .half {
            background blue
            position relative
            height 50%
            margin-bottom 30px

            &:nth-of-type(2){
                margin-bottom 0px
            }
        }
    }
}

/* Product Category Pages */

.onsale {
    display none
}

.product-grid {

    .product-item  {
        a {
            text-decoration none;
            display: block;
        }
    }

    .span9 {
        margin 0px
        float right
    }
    
    .product-item__image-wrapper {
       height: 160px;
    }
    
    .span4 {
        background-color white
        list-style none
        margin-bottom 30px
        transition 0.4s ease

        .lower-product {
            transition 0.4s ease
            .bluebar {
                transition 0.4s ease
                &:before {
                    transition 0.4s ease
                }
            }
        }

        img {
            width 100%
            padding 10px 20px 0px 20px
            max-height: 100%
        }

        h3 {
            display inline-block
            width 100%
            position relative
            padding 15px 20px 0px 20px
            font-size 16px
            margin-bottom 20px

            &:before {
                content ''
                display block
                width 84%
                height 2px
                background-color mbaBlack
                position absolute
                bottom -10px
                left 20px
            }
        }

        .price, .add_to_cart_button {
            transition 0.4s ease
        }

        .add_to_cart_button {
            color: mbaBlue!important
        }

        &:hover {


            transition 0.4s ease

            .lower-product {

                .price, .add_to_cart_button {
                    color white!important
                }

                .bluebar {

                    transition 0.4s ease

                    &:before {
                        transition 0.4s ease
                        width 100%
                    }
                }
            }
        }

        .lower-product {
            padding 10px
            background-color mbaLightGrey
            margin-top 20px
            position relative
            max-height 45px
            min-height 45px

            .price {
                top -8px
            }

            .price, .add_to_cart_button {
                position relative
                z-index 10
                margin-top 0px
            }

            .bluebar {
                width 100%
                min-height 100%
                position absolute
                bottom 0
                left 0
                width 100%
                z-index 4
                transition 0.4s ease

                &:before {
                    content ''
                    width 0%
                    height 100%
                    position absolute
                    top 0
                    left 0
                    background-color mbaBlue
                    transition 0.4s ease
                }
            }

            a {
                transition 0.4s ease
            }

            &:hover {

                transition 0.4s ease


                a {
                    color white!important
                    transition 0.4s ease
                }

                .price, p {
                    color white!important
                    transition 0.4s ease
                }

                .bluebar {


                    &:before {
                        width 100%
                        transition 0.4s ease
                    }
                }
            }
        }

        .woocommerce-LoopProduct-link {
            text-decoration none
        }

        .add_to_cart_button {
            font-family openSans
            color mbaBlack
            font-size 16px
            font-weight 600
            float right
            margin-bottom: 0px
            position relative
            text-decoration none
            text-transform uppercase
            margin-top 10px
            pointer-events none
        }

    }
}


.filter-bar {

    padding-bottom 50px

    @media(max-width mobile){
        h3, .filter-btn {
            width 45%
            display inline-block
        }
    }

    form {
        h3 {
            width 100%
            display block
        }
    }

    input[type="search"] {
        width 84%
        appearance none
        border-radius 0px
        border none
    }

    button[type="submit"] {
        background-color mbaBlue
        border 2px solid mbaBlue
        font-size 16px
        color white
        font-weight 400
        text-transform capitalize
        position relative
        text-decoration none
        padding 10px 30px
        margin 10px 0px 50px 0px
        transition 0.4s ease

        &:hover {
            transition 0.4s ease
            background-color darken(mbaBlue, 20%)

        }
    }

    input::-webkit-input-placeholder {
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    input:-moz-placeholder { /* Firefox 18- */
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    input::-moz-placeholder {  /* Firefox 19+ */
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    input:-ms-input-placeholder {
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    .option {
        width 100%
        display block

        input {
            margin-bottom 5px
            appearance normal
        }

        span {
            font-family openSans
            color #6c6d6f
            font-size 16px
            font-weight 500
            padding-left 15px
        }
    }

    label {
         font-family openSans
         color mbaBlue
         font-size 16px
         font-weight 600
         margin-bottom px
         margin-top 20px
         display block

         &:nth-of-type(1){
             margin-bottom 10px
         }
    }
    h3 {
        margin-bottom: 10px
    }
}

.in-stock {
    display none
}


    .type-product {

        .product_title {
            font-family openSans
            font-size 25px
            font-weight 700
            width 100%
            display inline-block

            @media(max-width mobile) {
                width 100%
            }

            &:after {
                display none
            }
        }
    }

.entry-summary {
    div {
        width 49%
        text-align right
        display inline-block

        .price {
            font-size 25px
        }
    }
}


.price {
    font-family openSans
    font-weight 700
    color mbaCopper
    font-size 16px
}

.slim-hero {
    width 100%
    background-position center center
    height 130px
    background-size 150%
    margin-bottom 20px!important

    @media(max-width mobile) {
        margin-bottom 20px!important
        background-size 200%
    }

    .row {
        height 100%
        display flex
        align-items center
        justify-content center

        .span5 {
            text-align right

            @media(max-width mobile) {
                display none
            }
        }

        h2 {
            color white
            font-size 40px
            margin-bottom 0px
            text-shadow 2px 2px 7px black

            &:after {
                display none
            }
        }
    }
}

.filter-grid {

    .grid {
        margin-bottom 20px

        a {
            vertical-align top
            background-image:url('../images/image-placeholder.jpg')
        }
    }

    margin-bottom 50px

    h2 {
        font-size 25px

        &:after {
            display none
        }
    }
}

div[itemprop="description"] {
    padding-bottom 40px
    border-bottom 1px solid mbaBlack

    @media(max-width 600px) {
        padding-bottom 20px
    }
}

div[itemprop="offers"] {
    width 30%
    display inline-block

    @media(max-width mobile) {
        width 100%
        display block
        padding-top 20px
    }
}

.single-product-content {

    border-bottom 1px solid mbaBlack

    form {
        width 68.9%
        padding 25px 0px
        display inline-block

        @media(max-width mobile) {
            width 100%
        }

        @media(max-width 480px) {
            width 100%
        }

        .quantity {
            width 60px
            display inline-block
            position relative
            margin-left 135px
            color #6c6d6f

            @media(max-width mobile) {
                margin-left 0px
                // display none
            }

            &:before {
                content 'Quantity'
                font-family openSans
                font-size 16px
                font-weight 700
                color #6c6d6f
                position absolute
                top 7px
                left -85px

                @media(max-width mobile) {
                    content ''
                    display none
                }
            }

            input {
                width 100%
                padding 9px
            }
        }

        button[type="submit"] {
            width 45%
            display inline-block
            float right

            @media(max-width mobile) {
                background-color mbaCopper
                font-size 12px
                height 100%
                padding 12px
                width 100%
            }
        }
    }
}

input[type="search"] {
    // border 2px solid mbaGrey
    border none
    font-size 14px
    padding 10px
    width 93%
}

.single__product__page{
    margin-bottom:70px;
    margin-top:50px

    @media(max-width mobile) {
        margin-top 30px
        margin-bottom 30px
    }
}
.single__product__page  {
    .price {
        font-size 25px
    }
}


#payment {
    border-radius 0px!important
}

.filter-group  {
    label {
        margin-bottom 0px!important
    }
}

.filter-checkboxes {
    li {
        list-style none

        label {
            width 80%
            display inline-block
            padding-left 10px
            margin-top 0px
            margin-bottom 0px
            font-weight 500
            font-size 16px
        }
    }
}

.line-space {
    border-bottom: 3px solid #323e6a;
    position:relative;
    top:60px;
}

.cart-discount {
    .woocommerce-Price-amount {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #000;
        text-transform: uppercase;
    }
}

.cart-discount {
    th {
            font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    }
}
.woocommerce-remove-coupon {
    font-size: 16px;
    color: black; 
    font-weight: bold;
}

.coupon {
    #coupon_code {
            height: 47px;
    position: relative;
    top: 0px;
    width: 200px;
    font-size: 14px;
    padding-left: 10px;
    }
}

.cart-discount {

}


.user-not-logged-in-price button,
.user-not-logged-in-price .quantity,
.user-not-logged-in-price .amount {
    display: none !important;
}