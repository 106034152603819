.contactform {
    padding-top 60px
    padding-bottom 60px
    // background-color #333
    background-color #3f4a6f
    border-top 10px solid mbaCopper
    position relative
    min-height 700px

    @media(max-width 1060px) {
        min-height 0px
    }

    p {
        margin: 0;
    }

    input, textarea {
        appearance none
        -webkit-appearance none
        border-radius 0px
    }

    @media(max-width 1060px) {
        padding-bottom: 0px;
    }

    .span8 {
        float none
        margin-left auto
        margin-right auto
    }

    h3 {
        color white
        font-size 29px
        font-family openSans
        font-weight 700
        text-align center
    }

    .little-form__field {
        padding: 10px!important
    }

    .little-form__field--submit {
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .little-form__field--textbox {
        width 49%
        display inline-block
        padding 10px

        @media(max-width mobile) {
            width 100%
        }

        input[type="text"], input[type="email"] {
            width 100%
            background-color white
            padding 10px
            border 0px
            outline none
            font-size: 16px;
            // color #fff
        }
    }

    .little-form__field--textarea {
        width 100%
        padding 20px

        textarea {
            width 97.8%
            background-color white
            padding 10px
            border 0px
            outline none
            height 175px
            resize none
            // color #fff
            font-size: 16px;

            @media(max-width mobile) {
                width 100%
            }
        }
    }
}

.little-form__col {
    width 100%
}

.little-form__row {
    display flex
    align-items center
    justify-content space-between
}

.little-form__label {
    font-family openSans
    color white
    font-size 18px
    display block
    font-weight 300
    padding-bottom 0
}

.little-form__field--submit {
    text-align center
}

.single_add_to_cart_button.disabled {
    float: right
    text-decoration: none
}

[type="submit"], .single_add_to_cart_button.disabled  {
    margin auto
    font-family openSans
    color white
    background-color mbaCopper
    font-size 16px
    font-weight 500
    padding 10px 30px
    transition 0.4s ease
    border 0px
    text-transform: capitalize

    &:hover {
        background-color darken(mbaCopper, 20%)
        transition 0.4s ease
        border 0px
    }
}

.triangle__copper {
	height 300px
	width 200px
	position absolute
	top 25%
	right 0
	background-image url('../images/triangle__copper.svg')
	background-size cover
	background-repeat no-repeat
	display flex
	padding-right 20px
	align-items: center;
    z-index: 2;

	@media(max-width 1060px) {
		display none
	}

	a {
		font-family openSans
		font-weight 700
		color white
		text-transform uppercase
		text-align right
		width 100%
		transition 0.5s ease;
        font-size:14px;
        text-decoration:none;
        transition 0.5s ease;

		&:hover {
			color mbaBlue
		}
	}
}

.triangle__copper--mobile {

	@media (min-width 1060px) {
		display none
	}

	display block
	height 170px
	width 320px
	margin auto
	position initial
	bottom 0%
	right 40%
	background-image url('../images/triangle--mobile.svg')
	background-size 100%
	background-repeat no-repeat
	display flex
	padding-right 20px
	align-items center

	a {
		font-family openSans
		font-weight 700
		color white
		padding-top 80px
		text-transform uppercase
		text-align center
		width 100%
		padding-left 20px;
        font-size:14px;
        text-decoration:none;

        @media(max-width 480px) {
            padding-bottom 25px
        }
	}

    @media(max-width 480px) {
        width 100%
        position relative
        bottom -15px
        left 0px
        min-width 0px
    }

}


span.wpcf7-not-valid-tip {
    font-size:18px
}
.wpcf7-response-output {
    color: white;
    font-size: 18px;
    border: none!important
}