footer {
	padding 40px 0px
	background mbaBlue
	// background-image url('../images/mba-globe.svg')
	background-size 90% auto
	background-position center center
	background-repeat no-repeat



	.span4 {
		@media(max-width mobile) {
			margin-bottom 30px!important
		}
	}

	a {
		text-decoration none
	}

	.footer__social {
		margin-right 20px
		height 25px
		fill white
		transition 0.5s ease

		&:hover {
			fill mbaCopper 
		}
	}

	.made-by {
		padding-top 40px
		text-align center

		a {
			color white
			text-decoration none
			transition: 0.5s ease;

			&:hover {
				color: mbaCopper
			}
		}

		p {
			color white
			font-family openSans
			font-size 16px
			font-weight 300
		}
	}

	h3 {
		color mbaCopper
		font-size 18px
		text-transform uppercase
		margin-bottom 10px
	}

	ul {

		li {
			list-style none
			font-family openSans
			font-weight 300
			font-size 16px
			color white
			margin-bottom 10px


			a {
				text-decoration none
				color white
				transition 0.4s ease

				&:hover {
					// color darken(white, 20%)
					color: mbaCopper
					transition 0.4s ease
				}
			}
		}
	}
}


.little-form {
	font-size 20px
	font-family openSans
	text-align center
	font-weight bold
	color white

	input, textarea {
		font-family openSans
		font-size 16px
		font-weight 500
	}
}
