#map{
    height: 400px;
            @media(max-width 767px) {
                width:100%!important
                display: block;
            }
}
.contact__box {
    vertical-align: top;
    margin-bottom 30px

    @media(max-width 767px) {
        width:100%!important
        display: block;
    }
    h4 {
        font-family openSans
        font-size 18px
        color mbaBlue
    }

    a {
        color mbaBlue
        text-decoration none
    }
}

.contact__information {
    font-family openSans
    font-size 20px
    margin-top 10px

    a {
        color mbaBlue
        text-decoration none
    }
}

.map-link {
    // color #6c6d6f!important
    transition 0.4s ease!important

    &:hover {
        color mbaBlue!important
        transition 0.4s ease!important
    }
}
