.header {
	padding 30px 0px 0px 0px
	background mbaBlue
	margin-bottom 50px

	// &.product-page {
	// 	margin-bottom 0px
	// }

	.mobile {
		display none

		@media(max-width mobile) {
			display block
		}
	}

	@media(max-width mobile) {
		padding 30px 0px
		padding-top 0px
	}

	/* Logo */

	.header__logo {
		min-width 167px
		min-height 100px
		max-width: 190px

		&.desktop {
			@media(max-width 600px) {
				display none
			}
		}

		&.mobile {
			display none
			@media(max-width 600px) {
				display block
				min-width 200px
				min-height 0px
				margin-top 20px
				max-width 50%
				max-height 100px
			}
		}
	}

	.header__logos {
		@media (max-width 600px) {
			text-align center
			margin-left 0px!important
			margin-top 20px
			width 100%!important

			img {
				margin-left auto
				margin-right auto
			}
		}
	}

	/* Basket Icon */

	.icon--basket {
		display inline-block
		position relative
		top 3px
		right -10px
		width 25px
		fill white
		transition 0.4s ease

		&:hover {
			// fill mbaRed
			fill mbaCopper
			transition 0.4s ease
		}
	}

	/* Search Bar and Contact Details */

	.span9 {
		text-align right
		float right!important
	}

	.search-container {
		display inline-block
		width 35%
		margin-left 25px
		position relative
	}

	form {

		a {
			position absolute
			left -50px
			top 5px
		}

		input[type="submit"] {

			position absolute
			top 2px
			right 0px
			width 30px
			height 100%
			background-color transparent
			background-image url('../images/icons/search-glass.svg')
			background-size 40%
			background-position center center
			background-repeat no-repeat
			border 0px
		}
	}

	.span9 {
		@media(max-width mobile) {
			display none
		}
	}

	.header__contact {

		margin-top 20px

		a {
			font-size 18px
			font-weight 900
			margin-left 20px
			transition 0.4s ease

			&:hover {
				// color mbaGrey
				color: mbaCopper
				transition 0.4s ease
			}

			strong {
				color mbaCopper
			}

			color white
			text-decoration none
		}
	}

	/* Navigation Menu */

	nav {
		width 100%
		background-color white
		margin-top 30px
		text-align center

		@media(max-width mobile) {
			display none
		}

		ul {
			margin-bottom 0px

			li {
				list-style none
				display inline-block
				line-height 0px
				position relative
				&:hover{
					background-color: mbaCopper;

					a{
						color: #fff!important;
						transition: 0s;
					}
				}

				&.menu-item-has-children {
					 ul {
						 display none
					 }


					 &:hover {

						 background mbaCopper

						 a {
							 color white
							 transition 0s ease
						 }

						 ul {
							 display block
							 position absolute
							 top 64px //73px
							 left 0
							 z-index 5
							 background-color mbaBlue

							 li {
								 display inline-block
								 transition 0s ease
								 width: 100%

								 &:hover {
									 background-color mbaCopper
									//  transition 0.4s ease
									 width: 100%;
								 }

								 a {
									 color white
								 }
							 }
						 }
					 }
				}

				&.current_page_item {

					a {
						color mbaBlue
					}
				}

				a {
					color mbaBlue
					text-decoration none
					font-family openSans
					font-size 16px
					font-weight 700
					text-transform uppercase
					display: block
					padding: 20px
					@media ( max-width: 1080px ) {
						padding: 10px
						line-height: 1.2
					}
					// transition 0.4s ease-in-out

					// &:hover {
					// 	transition 0.4s ease-in-out
					// 	color mbaBlue
					// }

				}
			}
		}
	}

	/* Mobile Slide In Menu */

	.mobile-search {
		position absolute
		top 50px
		width 100%
		z-index 10
		left -100vw
		transition 0.4s ease-in-out

		form {
			width 100vw
			margin-left -9px

			input[type="search"] {
				width 96vw
				height 45px
				border 0px
				-webkit-appearance none
				appearance none
				border none
			}
		}

		&.active {
			left 11px
			transition 0.4s ease-in-out
		}
	}

	.mobile-menu {
		height 100vh
		width 100%
		margin-top 40px
		background mbaBlue
		position absolute
		top 0
		z-index 2
		right -100vw
		transition 0.4s ease-in-out

		ul {
			list-style none

			li {
				text-align center
				padding 10px 0px
				border-top: 1px solid white
				position relative
				transition 0.5s ease-in-out

				&.menu-item-has-children {

					&:after {
						content ''
						display block
						position absolute
						height 15px
						width 15px
						top 20px
						right 10px
						background-image url('../images/icons/white-triangle.svg')
						transform rotate(0deg)
						background-position center center
						background-size contain
						background-repeat no-repeat
						transition 0.5s ease-in-out
					}

				&.open {

					background white
					transition 0.5s ease-in-out
					margin-bottom 0px
					padding-bottom 0px

					.sub-menu {

						margin-bottom 0px

						li {
							border-bottom 1px solid mbaBlack
						}
					}

					a {

						color mbaBlack
						transition 0.5s ease-in-out
					}

					&:after {
						transform rotate(180deg)
						transition 0.5s ease-in-out
						background-image url('../images/icons/mobile-navigation-arrow.svg')
					}
				}
			}

				&.current_page_item {
					background white

					a {
						color black
					}
				}

				&:nth-of-type(1) {
					border-top 0px
				}
				a {
					font-family openSans
					font-size 16px
					font-weight 500
					text-decoration none
					color white
					text-align center
				}
			}
		}

		&.active {
			right -4px
			width 104%
			transition 0.4s ease-in-out
		}
	}


		.menu-icon {
			position relative
			z-index: 4
			float right
			width: 35px;
			height: 25px;
			cursor: pointer;
			margin-top 10px
			transition 0.3s ease-in-out

			&.open {

				span {
					color white
					transition 0.3s ease
				}

				.bar {

					background rgba(white, 0%)

					&:after{
						transform: rotate(-45deg);
						bottom: 0px;
						transition 0.3s ease-in-out
						background white
					}

					&:before{
						transform: rotate(45deg);
						top: 0px;
						transition 0.3s ease-in-out
						background white
					}
				}
			}

			.bar {
				position: absolute;
				background white
				width: 35px;
				height: 5px;
				top: 50%;
				right: 0px;
				margin-top: -5px;
				opacity: 1;
				transition 0.3s ease-in-out

				&:before {
					position: absolute;
					background white
					width: 35px;
					height: 5px;
					top: 10px;
					content: "";
					display: block;
					transition 0.3s ease-in-out
				}

				&:after {
					position: absolute;
					background white
					width: 35px;
					height: 5px;
					bottom: 10px;
					content: ""
					display: block;
					transition 0.3s ease-in-out
				}

			}

		}
 }

 .mobile__header--container {
	 padding-bottom 10px
	 border-bottom 2px solid white
	 margin 0px!important
	 width 100%!important
 }

 .menu-main-menu-container {
	 @media(max-width mobile) {
		 background mbaBlue
	 }
 }

 .header__login {
	 font-size 15px
	 font-weight 300
	 margin-right 20px
	 font-family openSans
	 color white
	 text-decoration none
	 transition 0.4s ease

	 &:hover {
		 color mbaCopper
		 transition 0.4s ease
	 }
 }

.header__loggedin,
.header__loggedin a {
	font-size 15px
	display: inline-block
	font-weight 300
	margin-right 10px
	margin-left: 10px
	font-family openSans
	color white
	text-decoration none
	transition 0.4s ease
}

.header__loggedin a:hover {
	// color darken(white, 20%)
	color: mbaCopper
	transition 0.4s ease
}

.header__loggedin a:last-of-type {
	margin-right: 10px
}


.cookies-popup {
	position:fixed;
	z-index:99;
	width:100%;
	left:0;
	top:-155px;
	overflow:hidden;
	transition:0.4s ease;
	background-color: mbaBlue;
	border-bottom:2px solid #b3b3b3;
}
.cookies-popup--active {
	top:0px;
	max-height:200px;
}
.cookies-popup__inner {
	text-align:center;
}
.cookies-popup p {
	font-size:14px;
	padding:25px 15px;
	color:#ffffff;
	margin-bottom: 0px;
}
.cookies-popup a {
	color:white;
	text-decoration:none;
	font-size:14px;
	transition: 0.4s ease;
	margin:10px;
	font-weight: 500;
}
.cookies-popup a:hover {
	color: mbaCopper;
}
.cookies-popup span {
	color:white;
}

@media(max-width: 950px) {
	.cookies-popup span {
		display: block;
		margin-top: 15px;
	}
}