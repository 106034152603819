.staff-member-container{
    margin-left:0!important;
    
        @media(max-width 568px) {
            height: auto!important;
        }


    .staff-member-inner{
        min-height: 175px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid mbaTextGrey;
        box-sizing: content-box;
        position: relative;

        div{
            position: absolute;
            bottom: 0;
            left: 0;
        }

        @media(max-width 960px) {
            min-height: 250px;
        }
    }

    .staff-name{
        font-size: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        color: mbaBlue;
        margin-bottom: 0;
        display: inline-block;
        float: left;
        clear: left;
    }

    .staff-title{
        display: inline-block;
        float: left;
        clear: left;
    }

    img{
        width: 175px;
        height:175px;
        float: right;

        @media(max-width 960px) {
            float: left;
        }
    }

    .staff-description + p{
        clear: both;
    }

    .staff-quote-intro{
        font-size: 19px;
        // font-family: 'Open Sans', sans-serif;
        // font-weight: bold;
        color: mbaBlue;
    }

    .staff-quote + p{
        font-size: 19px;
        line-height: 31px;
        font-style: italic;
        font-weight: bold;
        color: mbaBlue;
    }

    .staff-quote-author{
        font-size: 19px;
        color: mbaBlue;
    }

}
.staff-member-container:nth-of-type(1){
    margin-top: 40px;
}
.staff-member-container:nth-of-type(2){
    margin-top: 40px;
        @media(max-width 568px) {
            margin-top: 0px;
        }
}
.staff-member-container:nth-of-type(odd){
    padding-right: 2.5%;
        @media(max-width 568px) {
                padding-right: 0;
        }
}
.staff-member-container:nth-of-type(even){
    padding-left: 2.5%;
        @media(max-width 568px) {
                padding-left: 0;
        }
}
