.login-page input,
.login-page textarea,
.login-page select {
  border: 2px solid #9E9E9E
}

.login-page select {
  height: 47px
  border-radius: 0px
  -webkit-border-radius: 0px
  padding: 10px
  background: none
  background-color: white
}