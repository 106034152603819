
.two_column_img_links {
  clear: both;
}

.two_column_img_links .span6 {
  border-bottom: 5px solid #323e6a;
}

.two_column_img_links a > div {
  min-height: 250px;
  border-bottom: 5px solid #323e6a;
  padding: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.two_column_img_links .span6:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(transparent, mbaBlue);
  background: linear-gradient(transparent, mbaBlue);
}

.two_column_img_links .span6:hover .overlay {
  width: 100%;
  background-color: rgba(50,62,106,0.7); 
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  left: 0;
}

.two_column_img_links .span6 .overlay {
  height: 100%;
  width: 0%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}


.two_column_img_links .span6 h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-transform: uppercase;
}

.two_column_img_links .span6 h2:after {
  display: none;
}

.two_column_img_links .span6 h2 strong {
  display: block;
}

.divide-line {
  width: 100%;
  height: 2px;
  background-color: #323e6a;
  display: block;
  clear: both;
  margin: 0 1.25% 40px 1.25% !important
}