

/* Three Pods for Cross Sell */

.three-pods {

    margin-bottom 50px

    .span4 {
        border-bottom 5px solid mbaBlue
        padding 15px
        min-height 225px

        &:before {
            content ''
            display block
            width 100%
            height 100%
            position absolute
            top 0
            left 0
            background linear-gradient(transparent,mbaBlue)
        }

        &:hover {
            .overlay {
                width 100%
                background-color rgba(mbaBlue, 70%)
                transition 0.4s ease-in-out
                left 0
            }
        }


        .overlay {
            height 100%
            width 0%
            position absolute
            top 0
            left 0
            transition 0.4s ease-in-out
        }

        h2 {
            font-family openSans
            font-weight 500
            font-size 28px
            line-height 30px
            color white
            margin-bottom 0px
            position absolute
            bottom 20px
            left 20px
            text-transform uppercase

            &:after {
                display none
            }

            strong {
                display block 
            }
        }
    }
}

.three-pods a:nth-of-type(-n+2) .span4 {
    @media ( max-width: 570px ) {
        margin-bottom: 40px
    }
}

.standard-content {
    // .span12 {
    //     &:nth-last-of-type(1){
    //         margin-bottom 50px
    //     }
    // }

    // .span6 {
    //     min-height 355px
    //
    //     @media(max-width mobile) {
    //         min-height 0px
    //     }
    // }

    .btn--copper {
        text-decoration: none!important
    }

    .standar-content .btn--copper {
        color: white!important;
    }

    .standar-content .btn--copper:hover {
        color: white!important;
    }
}

.col-container {
    float: left;
    position: relative;
    width 100%
}


//Testimonails Page
.testimonials-row{
    padding: 0px 15px;
}
.testimonial-container{
    width:50%;
    float: left;
    display: inline-block;
    min-height: 200px;
    margin-left: 0!important;

    img{
        max-height: 150px;
        max-width:100%;
    }

    p:nth-of-type(1){
        font-style: italic;
        font-size: 19px;
        line-height:31px;

    }
    p:nth-of-type(2){
        font-weight: bold;
        font-size: 19px;
        margin-bottom: 0;
        color: #323e6a;
    }
}

.testimonial-container:nth-of-type(odd){
    padding-right: 2.5%;
        @media(max-width 568px) {
            padding-right: 0;
        }
}
.testimonial-container:nth-of-type(even){
    padding-left: 2.5%;

        @media(max-width 568px) {
            padding-left: 0;
        }
}
.testimonial-container:nth-of-type(1){
    margin-top:40px;
}
.testimonial-container:nth-of-type(2){
    margin-top:40px;
    @media(max-width 568px) {
        margin-top: 0;
    }
}


//Fibre Laser - single image button
.single-pod-container {
    padding-left: 3%;
}
    .single-pod {
        border-bottom 5px solid mbaBlue
        padding 15px
        min-height 300px
        position: relative;
        margin-top: 40px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &:before {
            content ''
            display block
            width 100%
            height 100%
            position absolute
            top 0
            left 0
            background linear-gradient(transparent,black)
        }

        &:hover {
            .overlay {
                width 100%
                background-color rgba(mbaBlue, 70%)
                transition 0.4s ease-in-out
                left 0
            }
        }


        .overlay {
            height 100%
            width 0%
            position absolute
            top 0
            left 0
            transition 0.4s ease-in-out
        }

        h2 {
            font-family openSans
            font-weight 500
            font-size 28px
            line-height 30px
            color white
            margin-bottom 0px
            position absolute
            bottom 20px
            left 20px
            text-transform uppercase

            &:after {
                display none
            }

            strong {
                display block
            }
        }
    }
