
.woocommerce table.shop_table {
    border 0px
    border-collapse collapse
}

.woocommerce .cart input.button {
    font-size 14px
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    color: #000;
    background-color: #fff;
    border: 2px solid #6c6d6f;
    padding: 10px 30px;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 30px;
}

.checkout-container {
    .product__mainheading {
        margin-bottom: 70px
    }
}

.woocommerce-checkout-review-order-table {


    .product-name {
        width 50%
    }

    .amount {
        font-family Roboto
        font-size 16px
        font-weight 500
        color black
    }

    tr {
        &:nth-of-type(1) {
            td {
                border-top: 0px
            }
        }
    }

    .cart-subtotal, .order-total {
        td {
            text-align: right
        }
    }

    .cart_item {

        &:nth-of-type(1) {
            td {
                border-top: 2px solid mbaBlue
            }
        }
        td {
            &:nth-of-type(2){
                text-align center
            }

            &:nth-of-type(3){
                text-align right
            }
        }
    }

    thead {
        tr {
            th {
                &:nth-of-type(2) {
                    text-align center
                }

                &:nth-of-type(3) {
                    text-align right
                }
            }
        }
    }

    td, th {
        padding: 20px 0px!important;
    }
}

.woocommerce table.shop_table td {
    padding 25px 12px
}

.wc-proceed-to-checkout {
    a {
        color white!important
        background-color mbaCopper!important
        font-family openSans!important;
        text-align center
        border-radius 0px!important
        margin-bottom 60px!important
        width 100%!important
        padding 20px 40px!important
        transition 0.4s ease
        font-weight 500!important
        text-transform: capitalize

        &:hover {
            background-color darken(mbaCopper, 20%)!important
            transition 0.4s ease!important
        }
    }
}

.cart {

    tr {
        th {
            border-bottom 2px solid black
        }
    }

    .button {
        border-radius 0px!important;
    }

    .cart_totals {

        .checkout-button {
            transition 0.4s ease-in-out!important
            &:hover {
                transition 0.4s ease-in-out
                background-color #6d6d6d
            }
        }
    }



    width 100%

    .quantity {
        input[type="number"] {
            @media(max-width 600px) {
                // display none
            }
        }
    }

    .quantity {
        height 40px

        input[type="number"] {
            float right
        }
    }

    tr {

        td {

            padding 5px 0px
            text-align left

            &.product-price, &.product-subtotal  {

                .amount {
                    font-family Roboto
                    font-size 16px
                    font-weight 500
                    color black
                }

            }

            &.product-name {
                width 50%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &.product-price {
                width 15%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &.product-quantity {
                width 15%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &.product-subtotal {
                width 15%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &:nth-last-of-type(1) {
                text-align right
            }

        }

        th {
            font-family openSans
            font-size 20px
            color black
            font-weight 700
            text-transform uppercase
            border-bottom 2px solid mbaBlue
            text-align left

            @media(max-width 568px) {
                font-size 14px
            }

            &.product-name {
                width 50%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &.product-price {
                width 15%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &.product-quantity {
                width 15%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &.product-subtotal {
                width 15%

                @media(max-width 600px) {
                    width 25%
                }
            }

            &:nth-last-of-type(1) {
                text-align right
            }
        }
    }
}

.cart_totals {

    table {
        width 100%

        tr {
            td {
                width 20%
                text-align right
                border-bottom
                margin-top 30px
                border-bottom 1px solid colorText
                padding-bottom 15px
                padding-top 15px
                margin-top 30px
                margin-bottom 30px

                @media(max-width 768px) {
                    margin-top 0px
                    margin-bottom 0px
                }
            }

            th {
                width 80%
                text-align left
                padding-bottom 15px
                padding-top 15px
            }
        }
    }

    width 25%

    @media(max-width 800px) {
        width 100%
    }

    .cart-subtotal, .order-total, .shipping , .tax-rate {
        font-family Roboto
        font-size 16px
        font-weight 500
        color black
        text-transform uppercase

        .amount {
            font-family Roboto
            font-size 16px
            font-weight 500
            color black
            text-transform uppercase
        }
    }

    h2 {
        font-family openSans
        font-size 20
        font-weight 700
        color black
        text-transform uppercase
        position relative
        margin-bottom 10px

        &:after {
            content ''
            display block
            width 100%
            height 2px
            background-color mbaBlue
            position absolute
            bottom -10px
            left 0
        }
    }
}

.woocommerce .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals {
    width 33%
    margin 0 1.25%

    @media(max-width 768px) {
        width 100%
    }
}

.product-thumbnail {
    display none
}

.product-name {
    a {
        font-family Roboto
        font-size 17px
        font-weight 500
        color black
        text-decoration none
    }
}

.product-quantity, .product-price {
    text-align center!important
}

.quantity {

    text-align center

    input[type="number"] {
        font-size 14px
        text-align center
        width auto
    }
}

.woocommerce-message {
    border-top-color transparent!important
    font-size 16px
    max-width 1200px
    margin 0 auto!important
    padding-bottom 70px!important

    @media(max-width 600px) {
        display none
    }
}


.pagination--before {

    position relative
    top 13px

    @media(max-width 600px) {
        padding-right 7px!important
        top -20px
    }
}

.woocommerce-pagination, .pagination   {
    text-align right

    a {
        font-family openSans
        color mbaBlack
        font-size 16px
        font-weight 700
        text-decoration none

        svg {
            display none
        }
    }



    .current {
        color mbaBlue
        font-size 16px
        font-weight 700
        font-family openSans
    }

    li {
        list-style none
        display inline-block

        a {
            font-family openSans
            color mbaBlack
            font-size 16px
            font-weight 700
            text-decoration none
        }
    }
}

.pagination--after {
    padding-bottom 50px
    padding-right 25px
    position absolute
    bottom 13px
    right 0px
}

.singleproduct__img {

    img {
        width 100%
    }
    @media(max-width mobile) {
        img {
            margin-bottom 30px
        }
    }
}

.archive__title {
    margin-bottom 40px
    width 98.7%

    @media(max-width 600px) {
        margin-bottom 30px!important
    }

    .line-space {
        margin-left: 24px;
        width: 96.5%;
        position absolute

        @media(max-width 600px){
            width 97%!important
            margin-left 10px!important
        }

        @media(max-width 568px) {
            margin-left auto
        }

        @media(max-width 500px) {

            width 95%!important
        }
    }
}

.archive__breadcrumb {
    margin-top -85px
    margin-bottom 33px

    @media(max-width mobile) {
        margin-bottom 0px
    }

    .span6 {
        @media(max-width mobile) {
            position relative
            top -46px
        }
    }

    &:nth-last-of-type(1){
        .span6 {
            top -20px
        }
    }
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
    padding 10px
}

.woocommerce-billing-fields, .woocommerce-shipping-fields {
    h3 {
        margin-top 40px
    }
}

.payment_methods {
    font-size 18px
}

.woocommerce-checkout {
    th,tr,td {
        font-size 16px
    }
     #order_review_heading {
         margin-top 40px
         margin-bottom 15px
     }

    #place_order {
        background-color mbaBlue
        font-size 16px
        font-weight 500
        padding 10px 30px
        font-weight 600
        transition 0.4s ease

        &:hover {
            background-color darken(mbaBlue,20%)
            transition 0.4s ease
        }
    }
}

.woocommerce table.shop_table_responsive tr td, .woocommerce-page table.shop_table_responsive tr td {
    @media(max-width mobile) {
        width 100%!important
    }
}

.woocommerce-info {
    display none
}


.archive-prod-grid {
    @media(max-width mobile) {
        width 100%!important

        .span4 {
            &:nth-of-type(1){
                margin-top 30px
            }
        }
    }
}

.full-filter {
    padding-top 40px

    @media(max-width 680px) {
        display none
    }



    @media(max-width mobile) {
        .option {
            width 100%!important
        }
    }

    select {
        appearance none
    }

    input, select {
        background-color mbaBackground
        // border 2px solid  mbaBtnBlack
        border: none
        font-size 14px
        -webkit-appearance: none;
        padding 10px
        border-radius 0px!important
        width 100%
    }

    input::-webkit-input-placeholder {
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    input:-moz-placeholder { /* Firefox 18- */
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    input::-moz-placeholder {  /* Firefox 19+ */
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    input:-ms-input-placeholder {
       font-size 14px
       font-family openSans
       color mbaBtnBlack
    }

    .option {
        position relative

        &:after {
            content ''
            height 10px
            width 20px
            display block
            background-image url('../images/icons/blackarrow.svg')
            background-size contain
            background-position center center
            background-repeat no-repeat
            position absolute
            top 18px
            right 10px
            pointer-events none
        }
    }

    .brand {
        width 20%
    }

    .category {
        width 20%
    }

    .condition {
        width 12%
    }

    input[type="submit"] {
        width 10%
        padding 10px

        @media(max-width 900px) {
            width 10%
        }
    }

    input[type="text"] {
        width 35%
        position: relative;
        top -1px;
        max-height: 43px;

        @media(max-width 900px) {
            width 30%
        }
    }

    h3 {
        color white
        margin-bottom 20px

    }
}

.toplevelfilter__mobile, .tlf_mob {

    form {
        padding-top 0px
    }

    select {
        appearance: none;
        -webkit-appearance: none;
    }

    input, textarea, select {
        font-size 14px
        width 100%
        display block
        margin 10px 0px
        padding 10px
        background white
        border-radius 0px
        border 0px
        position relative
    }

    .option {
        position relative

        @media (max-width mobile) {
            width 100%
        }

        &:after {
            content ''
            display block
            width 10px
            height 10px
            position absolute
            top 25px
            right 10px
            background-image: url('../images/icons/mobile-navigation-arrow.svg')
            background-color white
            background-size contain
            background-position center
            background-repeat no-repeat
        }
    }
}

.actions input[type="submit"] {
    background-color mbaBlue!important
    color white!important;
    text-transform none!important
    border 3px solid mbaBlue!important
    font-weight 500!important
    font-size 16px
    transition 0.4s ease

    &:hover {
        background-color darken(mbaBlue, 20%)!important
        transition 0.4s ease !important
    }
}

.option {
    display inline-block
}

.cart_totals {
    h2 {
        &:after {
            background-color black
        }
    }
}

.place-order {
    margin-bottom 75px!important;
}

.filters {
    display block!important
}

.filter-bar {

    input[type="search"] {
        background #ffffff
        border none 
        text-transform: capitalize
    }


    @media(max-width 568px) {
        h3 {
            width 100%!important
        }
    }
}

.reduced-keyword {
    font-size: 16px
    padding 8px;
    width 44%
}

.product-list {
    padding-bottom 100px
}

.single-product-content {
    .woocommerce-Price-amount {
        position relative
        top -8px
    }
}

.outofstock {
    &.product {
        .woocommerce-Price-amount {
            top 3px
            }
    }
}

.out-of-stock {
    width 50%
    display inline-block
    padding 25px 0px
    text-align right
    margin-bottom 0px

    @media(max-width 600px) {
        text-align left
    }
}

.single__product__page .price {
    margin-bottom 0px
}

.woocommerce-error {
  &:before {
    left 10px
  }

  font-size 16px
  padding-left:40px!important;
}

.login__notice {
    background-color mbaBlue
    color white
    font-size 16px
    font-family openSans
    padding 30px
    margin-top 60px

    a {
        text-decoration underline
        color white
    }
}


// Logged In WooCommerce

.loggedin__navigation {
    list-style none

    li {
        list-style none

        a {
            color mbaGrey
            text-decoration none
        }
    }
}

.woocommerce-MyAccount-content {
    padding-bottom 50px
}

.woocommerce-MyAccount-content a {
    text-decoration: none
    color: mbaCopper
    font-weight: 500
    transition: 0.4s

    &:hover {
        color: mbaBlue
    }
}

input[type="submit"] {
    &.woocommerce-Button  {
        @media(max-width 568px) {
            margin-top 30px!important
        }
    }

}

.woocommerce-Address-title {
    .edit {
        font-family openSans
        font-size 18px
        color mbaGrey
        text-decoration underline
        margin-bottom 30px
    }
}
.woocommerce-Address {

    label {
        display none
    }

    address {
        font-style normal
        font-size 18px
        font-family openSans

    }
}

.is-active {
    a {
        p {
            font-weight 700
        }
    }
}

.woocommerce-FormRow {
    margin-bottom 25px!important
}

.order_details, .customer_details {

    .product-quantity {
        font-size: 17px
    }

    tr {
        th {
            padding-left 0px!important;
            text-transform uppercase
            font-size 18px
        }

        td {
            padding-left 0px!important;
            font-size 17px
            font-weight 500!important
        }
    }
}

.woocommerce-MyAccount-navigation-link--downloads, .woocommerce-MyAccount-navigation-link--payment-methods  {
    display none
}

.back-to-shop {
    font-family openSans
    color #6c6d6f
    font-size 16px
    font-weight 700
    text-decoration none;
    position relative
    top 13px
    left 10px
    z-index 9999
    transition 0.4s ease

    &:hover {
        transition 0.4s ease
        color #323e6a
    }
}

.filter-per-page {
    background-color white
    // border 2px solid  #b3b3b3
    border none
    font-size 14px
    -webkit-appearance: none;
    padding 10px
    border-radius 0px!important
    width 100%
}

.option-new {
    position relative
    width 93%

    &:after {
        content ''
        height 10px
        width 20px
        display block
        background-image url('../images/icons/blackarrow.svg')
        background-size contain
        background-position center center
        background-repeat no-repeat
        position absolute
        top 18px
        right 10px
        pointer-events none
    }
}

.woocommerce-LostPassword {
    a {
        color mbaBlue
        text-decoration none
        padding-top 15px
        font-weight bold
    }
}

.user-registration-form {

    form {
        border: 1px solid #d3ced2;
        padding: 20px;
        margin: 2em 0;
        text-align: left;
        border-radius: 5px;
        font-size: 16px;
        color: #515151;
        font-weight: 500;
    }

    label {
        color: #6c6d6f;
        font-size: 17px;
        font-weight: 300;
        padding-bottom: 5px;
        display: inline-block;
        // max-width:130px;
        position: relative;

        &:after {
            content '*'
            display: block;
            font-size:10px;
            color: mbaCopper
            font-weight: 700;
            position: absolute;
            top: 3px
            right:-10px
        }
    }

    textarea {
        border: 1px solid #dfdfdf;
        resize:none;
        height: 100px;
    }

    input, textarea {
        color: #6c6d6f;
        padding: 10px;
        margin-bottom: 15px;
        font-size: 14px;
    }

    .span6-wrap {
        display: inline-block;
        width: 49.3%

        input {
            width: 97.5%;
            padding: 10px;
        }
    }
}

.blue-submit {
    background-color: #323e6a!important;
    float: left;
    margin-left: 2px!important;
    margin-top: 10px!important;
}

.login-page {
    .span6 {
        @media(max-width tablet) {
            width: (percentage((12*column+22*margin)/max-width));
        }
    }

    form {
        @media(max-width tablet) {
            margin-bottom: 10px!important
        }
    }
}

.woocommerce form .form-row .required {
    font-size: 10px;
    position: relative;
    top: -5px;
}

.login-page {
    .woocommerce-Button {
        background-color: #323e6a!important;
        color: #ffffff!important;
        border-radius: 0px!important;
        font-weight: 500!important;
        font-size: 16px;
        padding: 15px 30px!important;
        margin-right: 30px!important;
        margin-bottom: 10px!important;
    }
}

.woocommerce-error {
    display: block
    margin: 0 auto 20px auto !important
    max-width: 1170px
    width: 100%
}

@media ( max-width: 500px ) {
    .register .form-row {
        width: 100% !important;
        margin-bottom: 15px !important;
    }
}


.woocommerce a.remove {
    color: mbaCopper!important;

    &:hover {
        background-color: mbaCopper!important;
    }

}


.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message .button, .woocommerce-page .woocommerce-error .button, .woocommerce-page .woocommerce-info .button, .woocommerce-page .woocommerce-message .button {
    text-transform: capitalize;
}