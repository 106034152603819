.standard-content {

    a {
        color mbaBlue
        transition 0.3 ease-in-out
        text-decoration underline

        &:hover {
            color: darken(mbaBlue, 20%) !important
        }

        &.btn--copper {
            color: white!important
            &:hover {
                color: white!important  
                text-decoration: none!important
            }
        }
    }

    .span6, .span12 {
        margin-bottom 40px

        ul {

            margin-left 25px
            font-size 12px

            li {
                margin-bottom 10px
            }

        	li {
        		font-family openSans
        		font-size 17px
        		color mbaTextGrey
        	}
        }
    }
}

.standard-content a {
  color: mbaCopper!important;
  font-weight: 500 !important;
  position: relative !important;
  bottom: auto !important;
  right: auto !important;
  text-decoration: none !important;
}

.standard-content .btn {
    padding: 10px 30px!important
}
