.slider-nav-container{
	padding-top:0px;
	margin-top: -15px
	font-family openSans
	padding-bottom 15px

	@media(max-width 840px) {
		.slider-nav__item {

			&.active-nav {
				&:after {
					right: 42%;
    				left: auto;
				}
			}
		}
	}

	@media(max-width 568px) {
		padding-left 0px
		padding-right 0px

		.slider-nav__item {
			margin-right 0px

			&.active-nav {
				&:after {
					right: 35%;
    				left: auto;
				}
			}
		}
	}
}

.slider-nav {
	list-style: none;
	padding: 0;
	text-align: center;
	font-size: 26px;
	text-transform: uppercase;
	line-height: 1.08;
	height:80px;

	li {
		display: inline-block;
	}
}

.slide__text {
		align-items center
		text-align left
		width 100%

		h2 {
			// font-family Roboto
			font-family: 'Audiowide', cursive;
			font-weight: normal;
			font-size 50px
			color white
			margin-bottom 10px
			margin-top 0px
			text-align left

			&:after {
				display none
			}

			@media(max-width 840px) {
				font-size 30px
				line-height 40px
			}

		}

		p {
			font-family openSans
			color white
			font-weight 300
			line-height 29px
			font-size 18px
			width 40%
			margin-bottom 50px
			text-align left

			@media(max-width 840px) {
				width 90%
			}
		}
	}

.slider-nav__item {
	border:solid mbaTextGrey 2px;
	height:50px;
	font-family openSans
	font-weight 500
	padding-left 0px
	padding-right 0px
	line-height:45px;
	margin-right 20px
	position relative
	transition 0.5s ease

	a {
		font-size 14px
		color mbaTextGrey!important
		line-height: 45px
		text-transform: uppercase
		text-decoration: none;
		font-family: 'Open Sans', sans-serif;
		font-weight: 600;
	}

	// &.slide-2 {
	// 	&:hover {
	// 		background-color #E42820
	// 		border 2px solid #E42820
	// 	}
	// }

	// &.slide-1 {
	// 	&:hover {
	// 		background-color #fc5c06
	// 		border 2px solid #fc5c06
	// 	}
	// }

	&:hover {


	
		border 2px solid mbaCopper
		background-color mbaCopper
		transition 0.5s ease

		a {
			color white!important
			transition 0.5s ease
		}
	}

	&.active-nav {

		border:solid mbaCopper 2px;

		a {
			color white!important
		}

		&:after {
			position:absolute;
			content:"";
			width:0px;
			height:0px;
			border-top: 15px solid mbaCopper;
			border-right: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid transparent;
			bottom: -30px;
    		right: 46%;
		}



		// &.slide-1 {
		// 	&:after {
		// 		position:absolute;
		// 		content:"";
		// 		width:0px;
		// 		height:0px;
		// 		border-top: 15px solid #fc5c06;
		// 		border-right: 15px solid transparent;
		// 		border-bottom: 15px solid transparent;
		// 		border-left: 15px solid transparent;
		// 		bottom: -30px;
		// 		right: 46%;
		// 	}

		// }

		// &.slide-2 {
		// 	&:after {
		// 		position:absolute;
		// 		content:"";
		// 		width:0px;
		// 		height:0px;
		// 		border-top: 15px solid #E42820;
		// 		border-right: 15px solid transparent;
		// 		border-bottom: 15px solid transparent;
		// 		border-left: 15px solid transparent;
		// 		bottom: -30px;
		// 		right: 46%;
		// 	}

		// }
	}
}

.slider-nav__item a{
	color:mbaGrey;
	display block
	width 100%
	font-family openSans
}

.slide {
	width: 100%;
	padding 0px!important
	height 100%;
}

// .slide > .slide-container {
// 	height: 500px;
// }

.slide__image {
	height: 100%
	width:100%;

	background-repeat: no-repeat
	background-position: center center
}

.slider-container{
	height:500px;
	overflow: hidden;
	margin-bottom 50px

	@media(max-width 840px) {
		height 400px
	}
}

.slider-container > .slider{
	height:100%;
}

.slide__text{
	color:mbaGrey;
	position:relative;
	z-index:1000;
}

.slide__text h2{
	color:white;
}

.slider-navigation{
	height:100px;
}

.slider-navigation ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.slider-navigation ul li{
    display:inline;
}

.slide-container{
	width:100%;
	height 100%
}

.active-nav{
	background-color: mbaCopper

	// &.slide-1 {
	// 	background-color #fc5c06
	// 	border: 2px solid #fc5c06
	// }

	// &.slide-2 {
	// 	background-color #e42820
	// 	border: 2px solid #e42820
	// }
}

.active-nav a{
	color:white;
}


.slide__image {

	height 100%
	position relative
	display flex
	align-items center
	background-size cover
	background-position center center
	background-repeat no-repeat

	@media(max-width 1100px) {
		background-size cover
	}

	&:before {
		content ''
		display block
		position absolute
		left 0
		top 0
		width 100%
		height 100%
		background: linear-gradient(to right, mbaBlue , transparent); /* Standard syntax */
	}
}

.flickity-viewport {
	min-height 100%
	height 100%!important
}

.flickity-slider {
	height 100%

	.row {
		width 100%

		.span12 {
			@media(max-width 600px) {
				padding 0px 0px 0px 20px
			}
		}
	}
}
// Automatic Movement and Changes to Tabs
